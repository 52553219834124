import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout cls="home">
    <Seo title="Alex's Website :3" />
    <h1 className="name">
      Hey, <br /> I'm Alex.
    </h1>
    <p className="under-name">
      Developer <span>//</span> Gamer <span>//</span> Founder <span>//</span>{" "}
      Cat Dad
    </p>
    <img
      className="nyan"
      alt="rainbows"
      src="https://raw.githubusercontent.com/gist/brudnak/aba00c9a1c92d226f68e8ad8ba1e0a40/raw/e1e4a92f6072d15014f19aa8903d24a1ac0c41a4/nyan-cat.gif"
    />
  </Layout>
)

export default IndexPage
